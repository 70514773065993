import { App, Plugin } from 'vue';
import GradeskResponsivityController from './GradeskResponsivityController';

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $eventsObserver: GradeskResponsivityController;
    }
}

const gradeskResponsivityController: Plugin = {
  install(app: App): void {
    const resizeController = new GradeskResponsivityController();
    const { globalProperties } = app.config;

    if (globalProperties) {
      globalProperties.$eventsObserver = resizeController;
    }
  },
};

export default gradeskResponsivityController;
