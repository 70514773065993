<template>
  <div class="gradesk-row main-axis-alignment_center gradesk-px-sm">
    <section class="gradesk-column about-section">
      <div
        class="gradesk-row gradesk-fs-xxl gradesk-fw-semibold main-axis-alignment_center"
      >Sobre o Gradesk</div>
      <p class="gradesk-row gradesk-fs-xs gradesk-ta-justify">
        O Gradesk é a agenda compartilhada
          perfeita para estudantes de todas as idades. Com ele, você pode organizar suas tarefas
          de estudos de forma eficiente e colaborativa, diminuindo o estresse e melhorando seu
          desempenho acadêmico.
      </p>
      <p class="gradesk-row gradesk-fs-xs gradesk-ta-justify">
        Nossa plataforma foi desenvolvida para auxiliar estudantes, independente do nível de
          escolaridade e de forma a evitar esquecimentos de tarefas, estruturar o aprendizado e
          auxiliar na organização do estudante, e totalmente de graça! Tenha na palma da sua mão
          toda a sua agenda escolar, acabaram os dias de esquecimento de trabalhos, apresentações
          e provas. Esteja sempre alerta sobre o que você ainda tem para entregar, e evite aquele
          desconforto de fazer as coisas de última hora.
      </p>
    </section>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
<style lang="less">
.about-section {
  max-width: 600px;
}
</style>
