import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import GradeskWebSite from '@/GradeskWebSite.vue';

import NotFound from '@/views/NotFound.vue';

import Home from '@/views/Home.vue';
import ForSchools from '@/views/ForSchools.vue';
import ContactUs from '@/views/ContactUs.vue';
import AboutUs from '@/views/AboutUs.vue';
import TermsAndConditions from '@/views/TermsAndConditions.vue';
import PrivacyTerms from '@/views/PrivacyTerms.vue';
import HowToDeleteUserData from '@/views/DeleteUserData.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'root',
    component: GradeskWebSite,
    children: [
      {
        path: '/',
        name: 'home',
        component: Home,
        meta: { title: 'Conheça o Gradesk!' },
      },
      {
        path: '/for-schools',
        name: 'forSchools',
        component: ForSchools,
        meta: { title: 'Gradesk para escolas' },
      },
      {
        path: '/contact-us',
        name: 'contactUs',
        component: ContactUs,
        meta: { title: 'Fale conosco' },
      },
      {
        path: '/about',
        name: 'aboutUs',
        component: AboutUs,
        meta: { title: 'Sobre o Gradesk' },
      },
      {
        path: '/terms-and-conditions',
        name: 'termsAndConditions',
        component: TermsAndConditions,
        meta: { title: 'Gradesk • Termos e condições' },
      },
      {
        path: '/privacy-terms',
        name: 'privacyTerms',
        component: PrivacyTerms,
        meta: { title: 'Gradesk • Política de privacidade' },
      },
      {
        path: '/how-to-delete-user-data',
        name: 'howToDeleteUserData',
        component: HowToDeleteUserData,
        meta: { title: 'Como deletar meus dados' },
      },
    ],
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
    meta: { title: 'Não encontrado :(' },
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  document.title = to.meta.title as string;
});

export default router;
