import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faGooglePlay, faAppStore } from '@fortawesome/free-brands-svg-icons';

import { createApp } from 'vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import resizeObserver from '@/entities/index';
import router from './router';

import App from './App.vue';

const app = createApp(App);

library.add(faGooglePlay, faAppStore, faBars);

app.use(router);
app.use(resizeObserver);

app.component('font-awesome-icon', FontAwesomeIcon);

app.mount('#app');
