<template>
  <div class="gradesk-column main-axis-alignment_center cross-axis-alignment_center w-100 f-h">
    <div class="gradesk-fs-xl gradesk-fw-semibold">Página não encontrada</div>
    <img class="placeholder-img" src="@/assets/images/lost.webp" alt="" />
    <div class="gradesk-fs-md">Não foi possível encontrar essa página</div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
<style lang="less" scoped>
.placeholder-img {
  max-height: 60vh;
  max-width: 60vh;
}

.f-h {
  height: 100vh;
}
</style>
