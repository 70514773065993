import { toDisplayString as _toDisplayString, withKeys as _withKeys, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.navigate && _ctx.navigate(...args))),
    onKeydown: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.navigate && _ctx.navigate(...args)), ["enter"])),
    class: _normalizeClass([
    'navbar-chips',
    'gradesk-column',
    'main-axis-alignment_center',
    'gradesk-px-md',
    'gradesk-ml-sm',
    'gradesk-fs-sm',
    'prevent-select',
    { selected: _ctx.isRouteSelected() }
  ])
  }, _toDisplayString(_ctx.label), 35))
}