<template>
  <div
    @click="navigate"
    @keydown.enter="navigate"
    :class="[
    'navbar-chips',
    'gradesk-column',
    'main-axis-alignment_center',
    'gradesk-px-md',
    'gradesk-ml-sm',
    'gradesk-fs-sm',
    'prevent-select',
    { selected: isRouteSelected() }
  ]">{{ label }}</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    label: String,
    route: String,
  },
  methods: {
    isRouteSelected() {
      if (this.$route.name === this.route) return true;
      if (this.$route.path === this.route) return true;
      return false;
    },
    navigate() {
      if (!this.route) {
        console.warn('GRADESK WEBAPP ERROR: Navigation chip route was undefined');
        return;
      }

      const routes = this.$router.getRoutes();
      const routePaths = routes.map((route) => route.path);
      const routeNames = routes.map((route) => route.name);

      if (routePaths.includes(this.route)) {
        this.$router.push(this.route);
        return;
      }

      if (routeNames.includes(this.route)) {
        this.$router.push({ name: this.route });
        return;
      }

      console.warn(`GRADESK WEBAPP ERROR: Route "${this.route}" was not found!`);
    },
  },
});
</script>
<style lang="less" scoped>
.navbar-chips {
  border-radius: 15px;
  background-color: #FFFFFF;
  color: #EF5B18;
  cursor: pointer;
  height: 30px;

  border: 1px solid #FFFFFF;

  &:hover {
    border: 1px solid #EF5B18;
  }

  &.selected {
    color: #FFFFFF;
    background: #EF5B18;
    border: 1px solid #EF5B18;

    &:hover {
      background: #ff6d04;
    }
    &:active {
      background: #cf5702;
    }
  }
}
</style>
