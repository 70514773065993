interface IGradeskEventListener {
  id: number;
  onEvent: (Window: Window, event: UIEvent | MouseEvent) => void;
  reference?: string;
}

export default class GradeskWindowEventsObserver {
  windowResizeListeners: IGradeskEventListener[];

  windowClickListeners: IGradeskEventListener[];

  windowOutsideClickObservers: IGradeskEventListener[];

  windowEventListenerIndex: number;

  constructor() {
    this.windowResizeListeners = [];
    this.windowClickListeners = [];
    this.windowOutsideClickObservers = [];
    this.windowEventListenerIndex = -1;

    window.addEventListener('resize', (_) => this.onViewResize(_));
    window.addEventListener('click', (_) => this.onClick(_));
  }

  listenWindowResize(onEvent: (window: Window, event: UIEvent) => void): number {
    this.windowEventListenerIndex += 1;

    this.windowResizeListeners.push({
      id: this.windowEventListenerIndex,
      onEvent,
    });

    return this.windowEventListenerIndex;
  }

  listenWindowClick(onEvent: (window: Window, event: UIEvent | MouseEvent) => void): number {
    this.windowEventListenerIndex += 1;

    this.windowClickListeners.push({
      id: this.windowEventListenerIndex,
      onEvent,
    });

    return this.windowEventListenerIndex;
  }

  detectClicksOutsideFrom(
    referenceId: string,
    onEvent: (window: Window, event: UIEvent | MouseEvent) => void,
  ): number {
    this.windowEventListenerIndex += 1;

    this.windowOutsideClickObservers.push({
      id: this.windowEventListenerIndex,
      onEvent,
      reference: referenceId,
    });

    return this.windowEventListenerIndex;
  }

  removeWindowResizeListener(listenerId: number): boolean {
    const listenerRef = this.windowResizeListeners.find((listener) => listener.id === listenerId);
    if (!listenerRef) return false;

    const listenerIndex = this.windowResizeListeners.indexOf(listenerRef);
    this.windowResizeListeners.splice(listenerIndex, 1);
    return true;
  }

  removeWindowClickListener(listenerId: number): boolean {
    const listenerRef = this.windowClickListeners.find((listener) => listener.id === listenerId);
    if (!listenerRef) return false;

    const listenerIndex = this.windowClickListeners.indexOf(listenerRef);
    this.windowClickListeners.splice(listenerIndex, 1);
    return true;
  }

  removeOutsideClickObserver(listenerId: number): boolean {
    const listenerRef = this.windowOutsideClickObservers.find(
      (listener) => listener.id === listenerId,
    );
    if (!listenerRef) return false;

    const listenerIndex = this.windowOutsideClickObservers.indexOf(listenerRef);
    this.windowOutsideClickObservers.splice(listenerIndex, 1);
    return true;
  }

  onViewResize(event: UIEvent): void {
    this.notifyResizeListeners(window, event);
  }

  onClick(event: MouseEvent): void {
    this.notifyClickListeners(window, event);
    this.observeClicksOutside(window, event);
  }

  observeClicksOutside(windowState: Window, event: UIEvent | MouseEvent): void {
    const { target } = event;
    const activeObservers: Map<string, boolean> = new Map<string, boolean>();

    this.windowOutsideClickObservers.forEach((observer) => {
      if (observer.reference) activeObservers.set(observer.reference, false);
    });

    const wantedIds = [...activeObservers.keys()];

    let appFound = false;
    let targetElement: any = target;

    while (!appFound) {
      if (targetElement.id === 'app') {
        appFound = true;
        break;
      }

      if (wantedIds.includes(targetElement.id)) activeObservers.set(targetElement.id, true);

      targetElement = targetElement.parentNode;
    }

    activeObservers.forEach((value, key) => {
      if (!value) {
        this.windowOutsideClickObservers.filter(
          (observer) => observer.reference === key,
        ).map(
          (observer) => observer.onEvent(windowState, event),
        );
      }
    });
  }

  notifyResizeListeners(windowState: Window, event: UIEvent): void {
    this.windowResizeListeners.map((listener) => listener.onEvent(windowState, event));
  }

  notifyClickListeners(windowState: Window, event: MouseEvent): void {
    this.windowClickListeners.map((listener) => listener.onEvent(windowState, event));
  }
}
