<template>
  <div id="home-page-sandwich-menu">
    <button
      class="menu-button gradesk-pa-sm"
      @click="toggleDropdownMenu"
      @keydown.enter="toggleDropdownMenu"
    >
      <font-awesome-icon class="menu-icon" icon="fa-solid fa-bars" />
    </button>
    <div
      class="dropdown-menu gradesk-column gradesk-pt-xs gradesk-pb-xxs gradesk-px-xs"
      v-show="toggleMenu"
    >
      <div
        :class="[
          'menu-item',
          'gradesk-mb-xxs',
          'gradesk-pa-xs',
          'prevent-select',
          { selected: isRouteSelected(path) }
        ]"
        @click="navigate(path.route)"
        @keydown.enter="navigate(path.route)"
        v-for="path of (menuData as ISandwichMenuPath[])"
        :key="path.id"
      >{{ path.label }}</div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

interface ISandwichMenuPath {
  id: number,
  label: string,
  route: string,
}

export default defineComponent({
  name: 'GradeskSandwichMenu',
  props: {
    menuData: Array,
  },
  data() {
    return {
      toggleMenu: false,
      listenerId: null as number | null,
    };
  },
  mounted() {
    this.listenerId = this.$eventsObserver.detectClicksOutsideFrom('home-page-sandwich-menu', () => {
      this.toggleMenu = false;
    });
  },
  unmounted() {
    if (this.listenerId) {
      this.$eventsObserver.removeOutsideClickObserver(this.listenerId);
    }
  },
  methods: {
    toggleDropdownMenu() {
      this.toggleMenu = !this.toggleMenu;
    },
    isRouteSelected(menuItem: ISandwichMenuPath) {
      if (this.$route.name === menuItem.route) return true;
      if (this.$route.path === menuItem.route) return true;
      return false;
    },
    navigate(path: string) {
      if (!path) {
        console.warn('GRADESK WEBAPP ERROR: Navigation chip route was undefined');
        this.toggleDropdownMenu();
        return;
      }

      const routes = this.$router.getRoutes();
      const routePaths = routes.map((route) => route.path);
      const routeNames = routes.map((route) => route.name);

      if (routePaths.includes(path)) {
        this.$router.push(path);
        this.toggleDropdownMenu();
        return;
      }

      if (routeNames.includes(path)) {
        this.$router.push({ name: path });
        this.toggleDropdownMenu();
        return;
      }

      console.warn(`GRADESK WEBAPP ERROR: Route ${path} was not found!`);
      this.toggleDropdownMenu();
    },
  },
});
</script>
<style lang="less" scoped>
.menu-button {
  cursor: pointer;
  border-radius: 40px;

  background-color: transparent;
  border: none;

  &:hover {
    background-color: #5858581f;
  }
  &:active {
    background-color: #58585848;
  }
}

.menu-icon {
  height: 24px;
  width: 24px;
}

.dropdown-menu {
  background-color: #FFF;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  position: absolute;
  z-index: 1;

  right: 8px;
  width: 200px;
}

#home-page-sandwich-menu {
  position: relative;
}

.menu-item {
  border-radius: 5px;
  background-color: #FFFFFF;
  cursor: pointer;

  &:hover {
    background-color: #ffdac0;
  }
  &:active {
    background-color: #ff9a52;
  }
  &.selected {
    background-color: #FF7C1E;
    color: #FFFFFF;

    &:hover {
      background-color: #f16a0a;
    }
    &:active {
      background-color: #cb5a09;
    }
  }
}
</style>
