<template>
  <div class="gradesk-row main-axis-alignment_center">
    <div class="gradesk-column gradesk-px-sm">
      <div
        :class="[
          'gradesk-row',
          'gradesk-fs-xl',
          'gradesk-fw-semibold',
          'main-axis-alignment_center',
          'gradesk-ta-center'
        ]"
      >
        Termos e condições de uso
      </div>
      <section class="gradesk-column about-section gradesk-mt-xl">
        <div class="gradesk-row gradesk-fs-md gradesk-fw-bold">
          Introdução
        </div>
        <p class="gradesk-row gradesk-fs-xs gradesk-ta-justify">
          Bem-vindo ao <strong>GRADESK</strong>, uma plataforma de agenda compartilhada e
           colaborativa para estudantes desenvolvida pela <strong>GRADESK TECNOLOGIA DA INFORMAÇÃO
           LTDA</strong>. Estes Termos e Condições de Uso ("Termos") regem o uso do nosso aplicativo
           ("o Aplicativo", ou apenas “GRADESK” ou “app”) e constituem um acordo legalmente
           vinculativo entre você (“o usuário”) e a <strong>GRADESK TECNOLOGIA DA INFORMAÇÃO LTDA
           </strong>. Ao acessar ou usar o Aplicativo, você concorda com estes Termos. Se você não
           concorda com estes Termos, por favor, não use o Aplicativo. O <strong>GRADESK</strong> é
           uma plataforma que permite aos usuários organizarem seus compromissos e tarefas escolares
           em um ambiente colaborativo e compartilhado. Esperamos que o <strong>GRADESK</strong>
           possa ser uma ferramenta útil e eficaz para ajudá-lo a gerenciar sua vida acadêmica.
           Agradecemos por escolher usar o <strong>GRADESK</strong>.
        </p>
      </section>
      <section class="gradesk-column about-section gradesk-mt-xl">
        <div class="gradesk-row gradesk-fs-md gradesk-fw-bold">
          Dos termos de uso
        </div>
        <p class="gradesk-row gradesk-fs-xs gradesk-ta-justify">
          Ao utilizar o <strong>GRADESK</strong>, você declara que leu e concorda com estes Termos e
           Condições de Uso. Se você não concorda com estes Termos, reiteramos que você não deve
           utilizar o aplicativo ou qualquer serviço da plataforma. Ao utilizar o
           <strong>GRADESK</strong>, você também concorda em cumprir todas as leis e regulamentações
           aplicáveis. A <strong>GRADESK TECNOLOGIA DA INFORMAÇÃO LTDA</strong> reserva o direito de
           modificar estes Termos a qualquer momento, e tais modificações entrarão em vigor
           imediatamente após a publicação dos Termos modificados no aplicativo. Ao continuar a usar
           o <strong>GRADESK</strong> após a publicação de tais modificações, você concorda em estar
           vinculado aos Termos modificados.
        </p>
      </section>
      <section class="gradesk-column about-section gradesk-mt-xl">
        <div class="gradesk-row gradesk-fs-md gradesk-fw-bold">
          Restrições de uso
        </div>
        <p class="gradesk-row gradesk-fs-xs gradesk-ta-justify">
          Para manter o <strong>GRADESK</strong> como uma plataforma segura, amigável e saudável
           para todos os que desejem utilizá-lo, é esperado que você como um usuário do
           <strong>GRADESK</strong> tome os cuidados cabíveis. Por isso reiteramos aqui, algumas -
           mas não todas - boas práticas que você deve seguir ao usar nosso aplicativo:
        </p>
        <ol class="gradesk-fs-xs gradesk-ta-justify">
          <li>Não é permitido compartilhar informações de acesso à conta do <strong>GRADESK</strong>
            com terceiros ou permitir que terceiros usem sua conta.
          </li>
          <li>É proibido usar o <strong>GRADESK</strong> para fins ilegais ou não autorizados.
             Lembre-se, você é responsável por tudo o que coloca na plataforma e como a utiliza!
          </li>
          <li>É proibido interferir na segurança do aplicativo ou tentar acessar dados de outros
             usuários sem autorização.
          </li>
          <li>Não é permitido usar o <strong>GRADESK</strong> para enviar spam ou mensagens não
             solicitadas.
          </li>
          <li>É proibido modificar, copiar ou distribuir o código-fonte ou o design do aplicativo
             sem autorização expressa da <strong>GRADESK TECNOLOGIA DA INFORMAÇÃO LTDA</strong>.
          </li>
          <li>Não é permitido usar o <strong>GRADESK</strong> para coletar informações pessoais de
             outros usuários. Os dados de usuários dentro da plataforma do <strong>GRADESK</strong>
             estão sob custódia da <strong>GRADESK TECNOLOGIA DA INFORMAÇÃO LTDA</strong>, e em
             hipótese alguma podem ser coletados sem a autorização expressa da mesma.
          </li>
          <li>É proibido utilizar o <strong>GRADESK</strong> de maneira que possa prejudicar ou
             sobrecarregar os servidores do aplicativo, ou tendo em vista causar prejuízos à
             empresa ou a seus usuários.
          </li>
          <li>Não é permitido usar o <strong>GRADESK</strong> para publicar conteúdo adulto, ilegal,
             difamatório, discriminatório ou ofensivo. Isso engloba mensagens, links e mídia
             compartilhados e incluídos dentro da plataforma. Qualquer ocorrência de conteúdos desse
             tipo estão sujeitas a sanções legais cabíveis, e o <strong>GRADESK</strong> sempre
             colaborará com as autoridades legais quando solicitado.
          </li>
        </ol>
        <div class="gradesk-row gradesk-fs-sm gradesk-fw-semibold gradesk-mt-md">
          Sobre o uso por parte de menores
        </div>
        <p class="gradesk-row gradesk-fs-xs gradesk-ta-justify">
          O <strong>GRADESK</strong> é destinado a ser usado por pessoas com idade igual ou superior
           a 13 anos. Se você tem menos de 13 anos de idade, não pode usar o
           <strong>GRADESK</strong> sem o consentimento expresso de seus pais ou responsável legal.
           Caso seja menor de idade, você declara que tem autorização dos seus pais ou responsável
           legal para usar o aplicativo. A <strong>GRADESK TECNOLOGIA DA INFORMAÇÃO LTDA</strong>
           não coleta intencionalmente informações pessoais de menores de 13 anos de idade sem o
           consentimento dos pais ou responsável legal. Se tomarmos conhecimento de que coletamos
           informações pessoais de um menor de 13 anos sem o consentimento dos pais ou responsável
           legal, tomaremos medidas para excluir essas informações o mais rapidamente possível. Os
           pais ou responsável legal são responsáveis por supervisionar as atividades do menor no
           <strong>GRADESK</strong> e garantir que ele use o aplicativo de acordo com estes Termos e
           Condições de Uso.
        </p>
        <div class="gradesk-row gradesk-fs-sm gradesk-fw-semibold gradesk-mt-md">
          Sobre o uso para fins comerciais
        </div>
        <p class="gradesk-row gradesk-fs-xs gradesk-ta-justify">
          É vetado o uso do <strong>GRADESK</strong> para fins comerciais ou para redistribuir o
           aplicativo com vista à obtenção de ganhos financeiros. A utilização do
           <strong>GRADESK</strong> para fins comerciais só é permitida mediante a celebração de um
           acordo de licença separado entre a <strong>GRADESK TECNOLOGIA DA INFORMAÇÃO LTDA</strong>
           e o usuário ou organização interessada, estipulando as condições de uso do aplicativo
           para fins comerciais. Qualquer tentativa de utilizar o <strong>GRADESK</strong> para tais
           fins sem a celebração de um acordo de licença separado será considerada violação destes
           Termos e Condições de Uso.
        </p>
      </section>
      <section class="gradesk-column about-section gradesk-mt-xl">
        <div class="gradesk-row gradesk-fs-md gradesk-fw-bold">
          Uso de propriedade intelectual
        </div>
        <p class="gradesk-row gradesk-fs-xs gradesk-ta-justify">
          Ao utilizar o <strong>GRADESK</strong>, você reconhece que todo o conteúdo do aplicativo,
           incluindo, mas não se limitando a, o código-fonte, design, textos, imagens, animações,
           vídeos, áudios, marcas registradas, patentes e outros direitos de propriedade intelectual
           relacionados ao aplicativo são de propriedade exclusiva da
           <strong>GRADESK TECNOLOGIA DA INFORMAÇÃO LTDA</strong> ou de seus licenciadores. Você
           concorda em não reproduzir, distribuir, modificar, copiar, exibir publicamente, executar
           publicamente, publicar, licenciar, criar trabalhos derivados, transferir, vender ou de
           outra forma explorar comercialmente qualquer parte do conteúdo do
           <strong>GRADESK</strong>, a menos que expressamente autorizado por escrito pela
           <strong>GRADESK TECNOLOGIA DA INFORMAÇÃO LTDA.</strong>.
        </p>
        <p class="gradesk-row gradesk-fs-xs gradesk-ta-justify">
          Não obstante o disposto acima, a <strong>GRADESK TECNOLOGIA DA INFORMAÇÃO LTDA</strong>
           autoriza o uso de imagens, vídeos ou outros materiais relacionados ao
           <strong>GRADESK</strong> por usuários, influenciadores e veículos de mídia, desde que
           tais materiais sejam usados apenas para fins de divulgação, recomendação, promoção ou
           comentários sobre o aplicativo ou plataforma. Essa autorização não inclui o direito de
           reproduzir, distribuir, modificar ou explorar comercialmente qualquer parte do conteúdo
           do <strong>GRADESK</strong>, e qualquer uso que vá além desses fins deve ser
           expressamente autorizado por escrito pela
           <strong>GRADESK TECNOLOGIA DA INFORMAÇÃO LTDA</strong>.
        </p>
        <p class="gradesk-row gradesk-fs-xs gradesk-ta-justify">
          Ao enviar ou transmitir qualquer conteúdo para o <strong>GRADESK</strong>, incluindo, mas
           não se limitando a, textos, imagens, vídeos, áudios ou outros materiais, você concede à
           <strong>GRADESK TECNOLOGIA DA INFORMAÇÃO LTDA</strong> uma licença mundial, não
           exclusiva, livre de royalties e sublicenciável para usar, reproduzir, modificar,
           distribuir, exibir publicamente, executar publicamente, publicar e criar trabalhos
           derivados do conteúdo, em todos os meios de comunicação conhecidos ou futuros, sem
           qualquer tipo de compensação para você ou para terceiros. Você declara e garante que
           possui ou controla todos os direitos necessários para conceder a licença acima
           mencionada.
        </p>
        <p class="gradesk-row gradesk-fs-xs gradesk-ta-justify">
          A propriedade intelectual do <strong>GRADESK</strong> é protegida pelas leis de direitos
           autorais e outras leis de propriedade intelectual aplicáveis. Qualquer violação dos
           direitos de propriedade intelectual da
           <strong>GRADESK TECNOLOGIA DA INFORMAÇÃO LTDA</strong> será considerada uma violação
           destes Termos e Condições de Uso, sujeita a sanções legais cabíveis.
        </p>
      </section>
      <section class="gradesk-column about-section gradesk-mt-xl">
        <div class="gradesk-row gradesk-fs-md gradesk-fw-bold">
          Privacidade e coleta de dados
        </div>
        <p class="gradesk-row gradesk-fs-xs gradesk-ta-justify">
          O <strong>GRADESK</strong> leva a sua privacidade muito a sério. Ao utilizar o nosso
           aplicativo, você concorda com a coleta, armazenamento, processamento e uso de suas
           informações pessoais de acordo com a nossa <a href="/privacy-terms">Política de
           Privacidade</a>. Essas informações podem incluir, mas não se limitam a, seu nome,
           endereço de e-mail, dados de contato, informações de pagamento e outras informações de
           identificação pessoal que possamos solicitar de você. Todas as informações coletadas são
           tratadas de forma confidencial e serão usadas apenas para fornecer os serviços do
           <strong>GRADESK</strong> e melhorar a experiência do usuário. Acesse nossa Política de
           Privacidade para obter mais informações sobre como coletamos e usamos suas informações
           pessoais.
        </p>
        <p class="gradesk-row gradesk-fs-xs gradesk-ta-justify">
          Salientamos também que o <strong>GRADESK</strong> não vende ou repassa seus dados pessoais
           a terceiros, e os armazenamos de forma segura. Além disso, o mínimo de informações
           possível é coletado, sendo grande parte desses dados, métricas de uso do aplicativo não
           vinculadas aos usuários.
        </p>
      </section>
      <section class="gradesk-column about-section gradesk-mt-xl">
        <div class="gradesk-row gradesk-fs-md gradesk-fw-bold">
          Isenção de responsabilidade
        </div>
        <p class="gradesk-row gradesk-fs-xs gradesk-ta-justify">
          Ao utilizar o <strong>GRADESK</strong>, você reconhece e concorda que é um aplicativo
           desenvolvido para o uso destinado à estudantes, professores e instituições de ensino, e
           não foi desenvolvido para fins profissionais, sendo assim, ao utilizá-lo fora desse
           escopo você concorda que quaisquer perdas ou problemas que possa vir a ter, são de sua
           própria conta e risco.
        </p>
        <p class="gradesk-row gradesk-fs-xs gradesk-ta-justify">
          Além disso, você concorda que a <strong>GRADESK TECNOLOGIA DA INFORMAÇÃO LTDA</strong>
           não é responsável por quaisquer atrasos ou falhas na entrega das notificações do
           aplicativo, incluindo aquelas relacionadas ao seu dispositivo móvel ou conexão com a
           internet. O escopo de uso da plataforma não envolve a notificação ou o gerenciamento
           de atividades vitais ou de alto risco, mas se restringe apenas a tarefas escolares e
           assuntos de cunho acadêmico que não devem afetar negócios ou questões financeiras. O
           <strong>GRADESK</strong> não se responsabiliza por quaisquer perdas ou problemas
           decorrentes do uso da plataforma fora de seu escopo.
        </p>
        <p class="gradesk-row gradesk-fs-xs gradesk-ta-justify">
          Você utiliza o <strong>GRADESK</strong> por sua própria conta e risco, e a
           <strong>GRADESK TECNOLOGIA DA INFORMAÇÃO LTDA</strong> não será responsável por quaisquer
           danos decorrentes do uso ou da incapacidade de usar o aplicativo, incluindo, mas não se
           limitando a, danos por perda de dados, interrupção do negócio ou qualquer outra perda
           financeira ou de qulaquer natureza.
        </p>
      </section>
      <section class="gradesk-column about-section gradesk-mt-xl">
        <div class="gradesk-row gradesk-fs-md gradesk-fw-bold">
          Rescisão
        </div>
        <p class="gradesk-row gradesk-fs-xs gradesk-ta-justify">
          A qualquer momento, você pode encerrar sua conta e deixar de utilizar o
           <strong>GRADESK</strong>. Além disso, a
           <strong>GRADESK TECNOLOGIA DA INFORMAÇÃO LTDA</strong> pode rescindir ou suspender o seu
           acesso ao aplicativo a qualquer momento, com ou sem aviso prévio, caso você viole estes
           termos de uso, qualquer lei aplicável ou por decorrência de mandato judicial.
        </p>
      </section>
      <section class="gradesk-column about-section gradesk-mt-xl">
        <div class="gradesk-row gradesk-fs-md gradesk-fw-bold">
          Atualização destes termos e condições
        </div>
        <p class="gradesk-row gradesk-fs-xs gradesk-ta-justify">
          A <strong>GRADESK TECNOLOGIA DA INFORMAÇÃO LTDA</strong> se reserva o direito de atualizar
           ou modificar estes termos de uso do <strong>GRADESK</strong> a qualquer momento e sem
           aviso prévio. Tais mudanças serão efetivas imediatamente após sua publicação no
           aplicativo ou notificação por outros meios. O uso continuado do <strong>GRADESK</strong>
           após tais alterações constitui a sua concordância em estar vinculado a estes termos de
           uso atualizados.
        </p>
      </section>
      <section class="gradesk-column about-section gradesk-mt-xl">
        <div class="gradesk-row gradesk-fs-md gradesk-fw-bold">
          Lei aplicável e foro
        </div>
        <p class="gradesk-row gradesk-fs-xs gradesk-ta-justify">
          Para dirimir quaisquer controvérsias oriundas do presente instrumento, as partes elegem
           o foro da Comarca de Itapecerica da Serra, Estado de São Paulo, renunciando a qualquer
           outro, por mais privilegiado que seja. A lei aplicável ao presente instrumento será a
           legislação brasileira, especialmente o Código de Defesa do Consumidor e a Lei Geral de
           Proteção de Dados Pessoais (LGPD).
        </p>
      </section>
      <div class="gradesk-row about-section gradesk-mt-xl gradesk-mb-xxxl">
        <span class="gradesk-fs-xs gradesk-fw-bold gradesk-mr-xs">Última atualização:</span>
        <span class="gradesk-fs-xs gradesk-fw-normal">10/04/2023</span>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
<style lang="less" scoped>
.about-section {
  max-width: 600px;
}

strong {
  display: inline-block;
  font-weight: 600;
}

p {
  display: inline-block;
}

li {
  margin-top: 8px;
}

a {
  font-weight: 600;
  color: #FF7C1E;

  &:visited {
    color: #e76100;
  }
  &:hover {
    color: #ff9244;
  }
}
</style>
