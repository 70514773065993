<template>
  <div class="decorative-img-container">
    <Transition name="fade" appear>
      <img
        id="home-decorative-background-img"
        src="@/assets/decorativeElements/home-decorative-shape.png"
        alt=""
        class="prevent-select"
        v-if="$route.name === 'home'"
      />
    </Transition>
  </div>
  <nav
    :class="[
      'gradesk-row',
      'gradesk-py-sm',
      'gradesk-pl-sm',
      'main-axis-alignment_space-between',
      'cross-axis-alignment_center',
      'w-100',
      { 'add-shaddow': isScrolling }
    ]"
  >
    <RouterLink to="/" activeClass="gradesk-column cross-axis-alignment_center h-100">
      <img class="navbar-logo gradesk-ml-xs prevent-select"
      src="@/assets/brand/gradesk_fundo_transparente.svg"
      alt="Logo do Gradesk"
      />
    </RouterLink>

    <div v-if="toggleMobileResponsivity" class="gradesk-column gradesk-pr-md">
      <GradeskSandwichMenu :menuData="navigationPayload" />
    </div>
    <ul class="gradesk-row gradesk-pr-xxl gradesk-pl-none" v-else>
      <GradeskNavbarRouteChip
        v-for="option of navigationPayload"
        :key="option.id"
        :label="option.label"
        :route="option.route"
      />
    </ul>
  </nav>
  <router-view style="margin-top: 124px" v-slot="{ Component }">
    <transition name="switch" appear mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

import GradeskNavbarRouteChip from '@/components/homeComponents/GradeskNavbarRouteChip.vue';
import GradeskSandwichMenu from '@/components/SandwichMenu.vue';

export default defineComponent({
  components: {
    GradeskNavbarRouteChip,
    GradeskSandwichMenu,
  },
  data() {
    return {
      toggleMobileResponsivity: false,
      resizeListenerId: null as number | null,
      navigationPayload: [
        { id: 0, label: 'Início', route: 'home' },
        // { id: 1, label: 'Para escolas', route: 'forSchools' },
        { id: 2, label: 'Sobre', route: 'aboutUs' },
        // { id: 3, label: 'Fale conosco', route: 'contactUs' },
        { id: 4, label: 'Termos e condições de uso', route: 'termsAndConditions' },
        { id: 5, label: 'Política de privacidade', route: 'privacyTerms' },
      ],
      isScrolling: false,
    };
  },
  mounted() {
    this.toggleMobileResponsivity = this.shouldToggleResponsivity(window.innerWidth);
    this.setupResponsivityListener();

    window.addEventListener('scroll', (e) => { this.isScrolling = window.pageYOffset > 0; });
  },
  beforeUnmount() {
    if (this.resizeListenerId) {
      this.$eventsObserver.removeWindowResizeListener(this.resizeListenerId);
    }
  },
  methods: {
    setupResponsivityListener() {
      this.resizeListenerId = this.$eventsObserver.listenWindowResize(
        (windowState) => {
          this.toggleMobileResponsivity = this.shouldToggleResponsivity(windowState.innerWidth);
        },
      );
    },
    shouldToggleResponsivity(width: number) {
      return width < 962;
    },
  },
});
</script>

<style lang="scss">
@import "@/style/index.scss"
</style>

<style lang="less">
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
}

#app {
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #616161;
  width: 100%;
}

button {
  font-family: 'Quicksand', sans-serif;
  color: #616161;
}
</style>

<style lang="less" scoped>
.decorative-img-container {
  height: 100vh;
  width: 100vw;

  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;

  overflow: hidden;
}
#home-decorative-background-img {
  position: absolute;
  right: 0;
  top: 0;

  width: 75vw;
}
.navbar-logo {
  width: 154px;
}

nav {
  position: fixed;
  top: 0;
}

.add-shaddow {
  box-shadow: #3c40434d 0px 1px 2px 0px;
  background-color: white;
}
</style>
<style lang="less">
.switch-enter-from,
.switch-leave-to {
  transform: translateY(32px);
  opacity: 0;
}
.switch-enter-active,
.switch-leave-active {
  transition: all 200ms ease-in-out;
  overflow: hidden;
}
.switch-enter-to,
.switch-leave-from {
  transform: translateY(0);
  opacity: 1;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
