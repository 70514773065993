<template>
  <div class="home gradesk-py-md gradesk-px-md gradesk-column cross-axis-alignment_center">
    <section class="gradesk-row app-introduction-section">
      <div
        :class="[
          'gradesk-column',
          'app-introductory-text',
          'gradesk-pr-lg',
          'w-66',
          'cross-axis-alignment_center',
        ]"
      >
        <p class="gradesk-fs-xxl gradesk-fw-medium prevent-select">
          <span class="gradesk-tc-primary gradesk-fw-bold">Gradesk,</span>
          sua agenda escolar compartilhada e totalmente colaborativa!
        </p>
        <p class="gradesk-fs-sm gradesk-fw-medium gradesk-pr-lg prevent-select">
          Gerencie seus prazos, compartilhe informações de maneira instantânea e ganhe
           tempo e tranquilidade com todas as funcionalidades que essa ferramenta tem a oferecer!
        </p>

        <div  v-if="isMobile()" :class="[
          'gradesk-column',
          'gradesk-mt-md',
          'cross-axis-alignment_center',
          'call-to-action-column',
          'gradesk-rg-sm',
          'w-100',
        ]">
          <span class="gradesk-fs-md gradesk-fw-semibold">Baixe agora!</span>
          <a
            href="https://apps.apple.com/br/app/gradesk-agenda-escolar/id1611096845"
            :class="[
              'call-to-action-button',
              'gradesk-py-sm',
              'gradesk-px-md',
              'cursor-default',
              'gradesk-row',
              'cross-axis-alignment_center',
              'main-axis-alignment_center',
              'gradesk-cg-sm',
              'w-100',
            ]"
          >
            <div class="gradesk-column app-stores-button">
              <font-awesome-icon icon="fa-brands fa-app-store" size="xl" />
            </div>
            <span
              :class="[
                'gradesk-fs-sm',
                'gradesk-fw-bold',
              ]"
            >App store</span>
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=br.com.gradesk"
            :class="[
              'call-to-action-button',
              'gradesk-py-sm',
              'gradesk-px-md',
              'cursor-default',
              'gradesk-row',
              'cross-axis-alignment_center',
              'gradesk-cg-sm',
              'w-100',
              'main-axis-alignment_center',
            ]"
          >
            <div class="gradesk-column app-stores-button">
              <font-awesome-icon icon="fa-brands fa-google-play" size="xl" />
            </div>
            <span
              :class="[
                'gradesk-fs-sm',
                'gradesk-fw-bold',
              ]"
            >Google Play</span>
          </a>
        </div>
        <div
          v-else
          :class="[
            'gradesk-row',
            'gradesk-mt-md',
            'cross-axis-alignment_center',
            'call-to-action-row',
        ]">
          <div
            :class="[
              'gradesk-fs-sm',
              'gradesk-fw-bold',
              'gradesk-mr-sm',
              'gradesk-tc-primary',
              'cursor-default',
              'prevent-select',
            ]"
          >
            Baixe agora!
          </div>

          <div class="gradesk-column gradesk-pa-sm app-stores-button">
            <font-awesome-icon icon="fa-brands fa-google-play" size="2xl" />
          </div>
          <div class="app-stores-divider gradesk-mx-xxs"></div>
          <div class="gradesk-column gradesk-pa-sm app-stores-button">
            <font-awesome-icon icon="fa-brands fa-app-store" size="2xl" />
          </div>
        </div>
      </div>
      <div class="gradesk-column cross-axis-alignment_center app-mockup w-33 prevent-select">
        <img alt="
            Mockup com a página inicial do aplicativo do Gradesk em um celular de modelo fictício.
            " src="@/assets/images/mockup_home-page.svg"
          class="mockup gradesk-mb-md"
        />
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'GradeskLandingPage',
  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      }
      return false;
    },
  },
});
</script>
<style lang="less" scoped>
.app-introduction-section {
  max-width: 932px;
}
.app-introductory-text {
  flex-grow: 3;
}

.app-mockup {
  flex-grow: 1;
}
.mockup {
  width: 204px;
}

.call-to-action-column {
  max-width: 200px;
}

.call-to-action-button {
  background: #EF5B18;
  border-radius: 15px;
  border: none;
  color: white;
  text-decoration: none;

  &:hover {
    background: #ff6d04;
  }
  &:active {
    background: #cf5702;
  }
}

.app-stores-divider {
  width: 1px;
  background-color: #585858;
  height: 48px;
}

@media only screen and (max-width: 850px) {
  /* For mobile phones: */
  [class*="app-introduction-section"] {
    flex-direction: column !important;
    align-items: center !important;
  }
  [class*="app-mockup"] {
    margin-top: 64px !important;
    width: 100% !important;
  }
  [class*="app-introductory-text"] {
    width: 100% !important;
    padding-right: 0;
  }
  [class*="call-to-action-row"] {
    width: 100% !important;
    justify-content: center;
  }
}

.app-stores-button {
  border-radius: 40px;
}
</style>
