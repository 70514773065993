<template>
  <div class="gradesk-row main-axis-alignment_center">
    <div class="gradesk-column gradesk-px-sm">
      <div :class="[
        'gradesk-row',
        'gradesk-fs-xl',
        'gradesk-fw-semibold',
        'main-axis-alignment_center',
        'gradesk-ta-center'
      ]">
        Política de privacidade
      </div>
      <section class="gradesk-column about-section gradesk-mt-xl">
        <div class="gradesk-row gradesk-fs-sm gradesk-fw-bold">
          Última atualização: [11/04/2023]
        </div>
        <p class="gradesk-row gradesk-fs-xs gradesk-ta-justify">
          O Gradesk leva a privacidade dos usuários muito a sério e, por isso, desenvolvemos esta
           Política de Privacidade para descrever como coletamos, usamos, divulgamos, transferimos
           e armazenamos suas informações. Ao utilizar o Gradesk, você concorda com esta Política
           de Privacidade e com a coleta e uso de suas informações de acordo com a mesma.
        </p>
      </section>
      <section class="gradesk-column about-section gradesk-mt-xl">
        <div class="gradesk-row gradesk-fs-md gradesk-fw-bold">
          Informações que coletamos
        </div>
        <p class="gradesk-row gradesk-fs-xs gradesk-ta-justify">
          Coletamos informações que você nos fornece diretamente ao utilizar o Gradesk, como seu
           nome, endereço de e-mail e data de nascimento. Também podemos coletar informações quando
           você interage com o Gradesk, como informações sobre o dispositivo que você está usando,
           o que você faz dentro do aplicativo, o idioma do dispositivo e o navegador da web que
           você está usando - caso esteja em um navegador.
        </p>
      </section>
      <section class="gradesk-column about-section gradesk-mt-xl">
        <div class="gradesk-row gradesk-fs-md gradesk-fw-bold">
          Como utilizamos suas informações
        </div>
        <p class="gradesk-row gradesk-fs-xs gradesk-ta-justify">
          Seus dados pessoais (nome, e-mail e data de nascimento), são usados apenas para fins
           cadastrais, de login e de identificação. Já os dados de uso e de dispositivo são usados
           para duas finalidades: estatísticas de uso e correção de problemas.
        </p>
        <p class="gradesk-row gradesk-fs-xs gradesk-ta-justify">
          As métricas de uso permitem ao Gradesk saber quais funcionalidades dentro do aplicativo
           são mais usadas e como são usadas. Essas estatísticas guiam a tomada de decisão de novos
           desenvolvimentos e criação de produtos.
        </p>
        <p class="gradesk-row gradesk-fs-xs gradesk-ta-justify">
          Os dados sobre o dispositivo são utilizados em caso de problemas técnicos específicos de
           usuários ou falhas de funcionamento que se repitam com maior frequência em certos
           dispositivos. Também coletamos o idioma de seu dispositivo para a localização de
           notificações por push e comunicação em geral.
        </p>
      </section>
      <section class="gradesk-column about-section gradesk-mt-xl">
        <div class="gradesk-row gradesk-fs-md gradesk-fw-bold">
          Com quem compartilhamos suas informações
        </div>
        <p class="gradesk-row gradesk-fs-xs gradesk-ta-justify">
          O Gradesk não compartilha suas informações com terceiros, todos os dados inseridos dentro
           da plataforma ficam a disposição apenas do Gradesk.
        </p>
      </section>
      <section class="gradesk-column about-section gradesk-mt-xl">
        <div class="gradesk-row gradesk-fs-md gradesk-fw-bold">
          Transferência de suas informações
        </div>
        <p class="gradesk-row gradesk-fs-xs gradesk-ta-justify">
          O Gradesk se reserva ao direito de armazenar e fazer o backup de suas informações e dados
           dentro e fora de seu país, sempre tomando os devidos cuidados para manter seus dados
           seguros em qualquer ambiente de armazenamento que estejam.
        </p>
      </section>
      <section class="gradesk-column about-section gradesk-mt-xl">
        <div class="gradesk-row gradesk-fs-md gradesk-fw-bold">
          Como protegemos suas informações
        </div>
        <p class="gradesk-row gradesk-fs-xs gradesk-ta-justify">
          Utilizamos medidas de segurança razoáveis para proteger suas informações contra acesso,
           uso, alteração ou divulgação não autorizados. O Gradesk implementa sistemas de segurança
           padrão de mercado e técnicas gabaritadas e recomendadas por especialistas e por nossos
           provedores de serviços de nuvem. Essas praticas incluem, mas não se limitam a:
        </p>
        <ul class="gradesk-fs-xs gradesk-ta-justify">
          <li>Criptografia dos dados no banco de dados (não confundir com criptografia de ponta a
             ponta).
          </li>
          <li>Gestão de acessos utilizando ferramentas profissionais e IAM (traduzido do inglês:
             Gerenciamento de acessos e identidade).
          </li>
          <li>Sistema de autenticação social, que além de facilitar o acesso a plataforma também
             garante segurança de ponta para autenticação de usuários.
          </li>
          <li>Comunicação por HTTPS, que protege os dados enquanto trafegam entre o dispositivo
             do usuário e os servidores do <strong>Gradesk</strong>.
          </li>
        </ul>
      </section>
      <section class="gradesk-column about-section gradesk-mt-xl">
        <div class="gradesk-row gradesk-fs-md gradesk-fw-bold">
          Retenção de suas informações
        </div>
        <p class="gradesk-row gradesk-fs-xs gradesk-ta-justify">
          Retemos suas informações pelo tempo necessário para fornecer os serviços do Gradesk,
           cumprir nossas obrigações legais e resolver disputas. Caso o usuário encerre sua conta,
           seus dados serão deletados dentro da plataforma.
        </p>
      </section>
      <section class="gradesk-column about-section gradesk-mt-xl">
        <div class="gradesk-row gradesk-fs-md gradesk-fw-bold">
          Seus direitos
        </div>
        <p class="gradesk-row gradesk-fs-xs gradesk-ta-justify">
          Você tem o direito de acessar e corrigir suas informações pessoais mantidas pelo Gradesk.
           Também pode solicitar a exclusão de suas informações pessoais ou solicitar que não sejam
           mais usadas para fins de marketing. Entre em contato conosco para exercer esses direitos.
        </p>
      </section>
      <section class="gradesk-column about-section gradesk-mt-xl">
        <div class="gradesk-row gradesk-fs-md gradesk-fw-bold">
          Alterações nesta Política de Privacidade
        </div>
        <p class="gradesk-row gradesk-fs-xs gradesk-ta-justify">
          Podemos atualizar esta Política de Privacidade periodicamente. Publicaremos as alterações
           nesta página e atualizaremos a data da "Última atualização" no topo desta página.
        </p>
      </section>
      <section class="gradesk-column about-section gradesk-mt-xl gradesk-mb-xxxl">
        <div class="gradesk-row gradesk-fs-md gradesk-fw-bold">
          Como entrar em contato conosco
        </div>
        <p class="gradesk-row gradesk-fs-xs gradesk-ta-justify">
          Se tiver dúvidas ou preocupações sobre esta Política de Privacidade ou sobre nossas
           práticas de privacidade, entre em contato conosco por e-mail em
           <strong>contato@gradesk.com.br.</strong>
        </p>
      </section>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
<style lang="less" scoped>
.about-section {
  max-width: 600px;
}

strong {
  display: inline-block;
  font-weight: 600;
}

p {
  display: inline-block;
}

li {
  margin-top: 8px;
}</style>
