import { resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2d0a769e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "home-page-sandwich-menu" }
const _hoisted_2 = { class: "dropdown-menu gradesk-column gradesk-pt-xs gradesk-pb-xxs gradesk-px-xs" }
const _hoisted_3 = ["onClick", "onKeydown"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "menu-button gradesk-pa-sm",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdownMenu && _ctx.toggleDropdownMenu(...args))),
      onKeydown: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.toggleDropdownMenu && _ctx.toggleDropdownMenu(...args)), ["enter"]))
    }, [
      _createVNode(_component_font_awesome_icon, {
        class: "menu-icon",
        icon: "fa-solid fa-bars"
      })
    ], 32),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.menuData as ISandwichMenuPath[]), (path) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass([
          'menu-item',
          'gradesk-mb-xxs',
          'gradesk-pa-xs',
          'prevent-select',
          { selected: _ctx.isRouteSelected(path) }
        ]),
          onClick: ($event: any) => (_ctx.navigate(path.route)),
          onKeydown: _withKeys(($event: any) => (_ctx.navigate(path.route)), ["enter"]),
          key: path.id
        }, _toDisplayString(path.label), 43, _hoisted_3))
      }), 128))
    ], 512), [
      [_vShow, _ctx.toggleMenu]
    ])
  ]))
}