<template>
  <div class="gradesk-row main-axis-alignment_center gradesk-px-sm">
    <section class="gradesk-column about-section gradesk-mb-xxl">
      <h1 class="gradesk-row gradesk-fs-lg gradesk-fw-bold">Como apagar sua conta e informações do
        Gradesk</h1>
      <p class="gradesk-row gradesk-fs-xs gradesk-ta-justify">
        Em conformidade com a lei geral de proteção de dados (LGPD), o Gradesk permite que você
        mantenha o controle sob suas informações pessoais, ao permitir que você exclua seus dados
        e sua conta do Gradesk caso ache necessário.
      </p>
      <h2 class="gradesk-row gradesk-fs-sm gradesk-fw-bold gradesk-mt-md">Quais informações são
        coletadas pelo Gradesk?
      </h2>
      <p class="gradesk-row gradesk-fs-xs gradesk-ta-justify gradesk-my-none">
        Todas as informações que coletamos, e as razões pelas quais as coletamos podem ser
        encontradas em nossos <a href="/terms-and-conditions">Termos e Condições de uso</a>, e
        em nossa <a href="/privacy-terms">Política de privacidade</a>.
      </p>
      <h3 class="gradesk-row gradesk-fs-xs gradesk-fw-medium gradesk-mb-none">Mas podem ser
        resumidas a:</h3>
      <ul class="gradesk-my-none">
        <li class="gradesk-fs-xs">Seu nome;</li>
        <li class="gradesk-fs-xs">Seu e-mail;</li>
        <li class="gradesk-fs-xs">Sua data de nascimento e</li>
        <li class="gradesk-fs-xs">Informações e estatísticas de uso do aplicativo.</li>
      </ul>
      <h2 class="gradesk-row gradesk-fs-sm gradesk-fw-bold gradesk-mt-xl">Passo a passo</h2>
      <p class="gradesk-row gradesk-fs-xs gradesk-ta-justify gradesk-mt-none gradesk-mb-sm">
        Siga este passo a passo para deletar seus dados e sua conta do Gradesk.
      </p>

      <ol class="gradesk-my-none">
        <li class="gradesk-fs-xs">Vá para a página inicial do Gradesk.</li>
        <div class="w-100 gradesk-row main-axis-alignment_center gradesk-mt-xs gradesk-mb-xxl">
          <img
          style="width: 200px;"
          src="@/assets/tutorial_illustrations/delete_account_tutorial_steps/step1.svg" alt="">
        </div>
        <li class="gradesk-fs-xs">Clique no seu avatar</li>
        <div class="w-100 gradesk-row main-axis-alignment_center gradesk-mt-xs gradesk-mb-xxl">
          <img
          style="width: 200px;"
          src="@/assets/tutorial_illustrations/delete_account_tutorial_steps/step2.svg" alt="">
        </div>
        <li class="gradesk-fs-xs">
          Agora você deve estar no seu perfil. Aqui você pode ver as informações pessoais
          que você forneceu ao Gradesk, assim como sua foto de perfil que é fornecida ao fazer o
          login com o Google.</li>
        <div class="w-100 gradesk-row main-axis-alignment_center gradesk-mt-xs gradesk-mb-xxl">
          <img
          style="width: 200px;"
          src="@/assets/tutorial_illustrations/delete_account_tutorial_steps/step3.svg" alt="">
        </div>
        <li class="gradesk-fs-xs">
          Agora clique no botão de “Editar perfil”, que está no canto superior direito da sua tela.
        </li>
        <div class="w-100 gradesk-row main-axis-alignment_center gradesk-mt-xs gradesk-mb-xxl">
          <img
          style="width: 200px;"
          src="@/assets/tutorial_illustrations/delete_account_tutorial_steps/step4.svg" alt="">
        </div>
        <li class="gradesk-fs-xs">
          Este é o modal para editar o seu perfil. Você pode alterar certas informações pessoais
          aqui.</li>
        <div class="w-100 gradesk-row main-axis-alignment_center gradesk-mt-xs gradesk-mb-xxl">
          <img
          style="width: 200px;"
          src="@/assets/tutorial_illustrations/delete_account_tutorial_steps/step5.svg" alt="">
        </div>
        <li class="gradesk-fs-xs">
          Aqui é onde você entra no fluxo para deletar sua conta e seus dados pessoais. Clique no
          botão para apagar sua conta, que pode ser encontrado no canto superior direito do
          modal.</li>
        <div class="w-100 gradesk-row main-axis-alignment_center gradesk-mt-xs gradesk-mb-xxl">
          <img
          style="width: 200px;"
          src="@/assets/tutorial_illustrations/delete_account_tutorial_steps/step6.svg" alt="">
        </div>
        <li class="gradesk-fs-xs">
          Essa ação vai abrir um novo modal para confirmar que você realmente deseja apagar sua
          conta, e que essa ação é
          irreversível. Ao clicar em “Sim” você está confirmando que está ciente deste fato e de
          que sua decisão é final.
        </li>
        <div class="w-100 gradesk-row main-axis-alignment_center gradesk-mt-xs gradesk-mb-xxl">
          <img
          style="width: 200px;"
          src="@/assets/tutorial_illustrations/delete_account_tutorial_steps/step7.svg" alt="">
        </div>
        <li class="gradesk-fs-xs">
          Agora basta clicar em “Sim” para prosseguir.</li>
        <div class="w-100 gradesk-row main-axis-alignment_center gradesk-mt-xs gradesk-mb-xxl">
          <img
          style="width: 200px;"
          src="@/assets/tutorial_illustrations/delete_account_tutorial_steps/step8.svg" alt="">
        </div>
        <li class="gradesk-fs-xs">
          Este é o formulário para apagar sua conta e seus dados. Ele permite que você forneça um
          feedback para a equipe
          do Gradesk sobre o porque decidiu apagar sua conta. Isso nos ajuda a melhorar nossa
          plataforma cada vez mais.
          Mas é importante lembrar que o preenchimento desse formulário não é obrigatório, e você
          pode prosseguir clicando
          em “Deletar conta”.</li>
        <div class="w-100 gradesk-row main-axis-alignment_center gradesk-mt-xs gradesk-mb-xxl">
          <img
          style="width: 200px;"
          src="@/assets/tutorial_illustrations/delete_account_tutorial_steps/step9.svg" alt="">
        </div>
        <li class="gradesk-fs-xs">
          Após responder a pergunta do formulário, basta clicar no botão “Deletar conta” para
          prosseguir para o fluxo de
          excluir sua conta junto à plataforma pela qual você fez login.</li>
        <div class="w-100 gradesk-row main-axis-alignment_center gradesk-mt-xs gradesk-mb-xxl">
          <img
          style="width: 200px;"
                      src="@/assets/tutorial_illustrations/delete_account_tutorial_steps/step10.svg"
            alt=""
          >
        </div>
        <li class="gradesk-fs-xs">
          Aqui você receberá uma mensagem de despedida do Gradesk. Basta clicar em “Terminar” para
          seguir para a
          autenticação. Essa etapa é necessária para confirmar que é você quem está tentando apagar
          sua conta.</li>
        <div class="w-100 gradesk-row main-axis-alignment_center gradesk-mt-xs gradesk-mb-xxl">
          <img
          style="width: 200px;"
                      src="@/assets/tutorial_illustrations/delete_account_tutorial_steps/step11.svg"
            alt=""
          >
        </div>
        <li class="gradesk-fs-xs">
          Clique em “Terminar” para seguir para a reautenticação.</li>
        <div class="w-100 gradesk-row main-axis-alignment_center gradesk-mt-xs gradesk-mb-xxl">
          <img
          style="width: 200px;"
                      src="@/assets/tutorial_illustrations/delete_account_tutorial_steps/step12.svg"
            alt=""
          >
        </div>
        <li class="gradesk-fs-xs">
          Agora realize a autenticação novamente com o meio que você usou para criar sua conta do
          Gradesk. Caso você use
          um diferente, não será possível apagar sua conta, pois haverá diferença entre a conta
          autenticada atualmente, e a
          autenticada nesse passo.</li>
        <div class="w-100 gradesk-row main-axis-alignment_center gradesk-mt-xs gradesk-mb-xxl">
          <img
          style="width: 200px;"
                      src="@/assets/tutorial_illustrations/delete_account_tutorial_steps/step13.svg"
            alt=""
          >
        </div>
        <li class="gradesk-fs-xs">
          Agora, se tudo ocorreu bem e como neste passo a passo, sua conta será apagada assim que
          a reautenticação
          acabar, e você voltará para o início do Gradesk.</li>
      </ol>
      <h2 class="gradesk-row gradesk-fs-sm gradesk-fw-bold gradesk-mt-xxxl">Suporte</h2>
      <p class="gradesk-row gradesk-fs-xs gradesk-ta-justify gradesk-mt-none gradesk-mb-sm">
        Caso tenha ocorrido algum erro ou você necessite de qualquer suporte diretamente com a
        equipe do Gradesk, por favor entre em contato através do e-mail
        <strong>contato@gradesk.com.br</strong>
      </p>
    </section>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HowToDeleteUserData',
});
</script>
<style lang="less" scoped>
.about-section {
  max-width: 600px;
}

p {
  display: inline-block;
}

a {
  font-weight: 600;
  color: #FF7C1E;

  &:visited {
    color: #e76100;
  }

  &:hover {
    color: #ff9244;
  }
}
</style>
